import React from "react";

import { Container } from "./styles";

const Cta = ({ children, ...rest }) => {
  return (
    <Container to="/orcamento/" {...rest}>
      {children || "Solicite um Orçamento"}
    </Container>
  );
};

export default Cta;
