import React from "react";
import { AiOutlineInstagram } from "react-icons/ai";

import InputMask from "react-text-mask";

import NavMenu from "../NavMenu";
import Footer from "../Footer";
import Cta from "./CtaSend";

import { Container } from "./styles";

const InputPhone = (props) => {
  function mask(userInput) {
    const numbers = userInput.match(/\d/g);
    let numberLength = 0;
    if (numbers) {
      numberLength = numbers.join("").length;
    }

    if (numberLength > 10) {
      return [
        "(",
        /[1-9]/,
        /[1-9]/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ];
    }
    return [
      "(",
      /[1-9]/,
      /[1-9]/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ];
  }

  return <InputMask guide mask={mask} {...props} />;
};

const ContatoPage = () => {
  return (
    <>
      <NavMenu />
      <Container>
        <h1>Contato</h1>
        <hr />

        <article>
          <p>
            <b>Você tem alguma dúvida?</b>
            <br />
            <b>Fique a vontade para entrar em contato conosco!</b>
          </p>
          <br />
          <p style={{ marginTop: 20 }}>
            Av. Antônio Maria Coelho, 1472 - Centro
          </p>
          <p>Campo Grande-MS - CEP 79002-220</p>
          <p>Telefones: (67) 99950-3857 / (67) 99685-2943</p>
          <p>e-mail: contato@sfsolar.com.br</p>
          <a
            href="https://www.instagram.com/sfempresasolar/"
            title="@sfempresasolar"
          >
            <AiOutlineInstagram size={32} />
          </a>
        </article>

        <h2>Envie sua mensagem</h2>

        <form action="https://api.staticforms.xyz/submit" method="post">
          <input
            type="hidden"
            name="accessKey"
            value="445def1b-b2f6-431d-977c-01bcce57d8af"
          />

          <input type="text" name="honeypot" style={{ display: "none" }} />

          <label htmlFor="name">
            Nome Completo
            <input name="name" id="name" required />
          </label>
          <label htmlFor="email">
            E-mail
            <input type="email" name="email" id="email" required />
          </label>
          <label htmlFor="phone">
            Telefone para contato
            <InputPhone name="phone" id="phone" required />
          </label>

          <label htmlFor="msg">
            Mensagem
            <textarea name="message" id="msg" required />
          </label>

          <input
            type="hidden"
            name="redirectTo"
            value="https://www.sfsolar.com.br/contatosuccess/"
          />

          <Cta type="submit">Enviar mensagem</Cta>
        </form>
      </Container>
      <Footer />
    </>
  );
};

export default ContatoPage;
