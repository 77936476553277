import React from "react";

import SEO from "../components/seo";
import Layout from "../components/Layout";
import ContatoPage from "../components/Contato";

const Contato = () => {
  return (
    <Layout>
      <SEO title="Contato " />
      <ContatoPage />
    </Layout>
  );
};

export default Contato;
